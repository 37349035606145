import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Sticky from "react-stickynode"
import { useStaticQuery, graphql } from "gatsby"
import clsx from "clsx"

const Header = ({ siteTitle, navClass, headerClass }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          urls {
            login
            register
            webhooksDev
          }
        }
      }
    }
  `)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className={`header_area ${headerClass || ""}`}>
        <nav className="navbar navbar-expand-lg menu_one">
          <div className="container custom_container p0">
            <Link className="navbar-brand sticky_logo" to="/">
              <img
                src={require("../images/icon_logo_white.png")}
                style={{ maxHeight: "40px" }}
                alt="logo white"
              />
              <img
                src={require("../images/icon_logo.png")}
                style={{ maxHeight: "40px" }}
                alt="logo"
              />
              <span className="ml-2">{siteTitle}</span>
            </Link>
            <button
              className={clsx("navbar-toggler", { collapsed: !isOpen })}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>

            <div
              className={clsx("collapse navbar-collapse", { show: isOpen })}
              id="navbarSupportedContent"
            >
              <ul className={`navbar-nav menu ml-auto ${navClass || ""}`}>
                <li className="nav-item">
                  <Link to="/" className="nav-link" activeClassName="active">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/about"
                    className="nav-link"
                    activeClassName="active"
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/integrations"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Integrations
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    to="/pricing"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Pricing
                  </Link>
                </li> */}
                <li className="nav-item">
                  <a
                    href={data.site.siteMetadata.urls.login}
                    className="nav-link"
                  >
                    Login
                  </a>
                </li>
              </ul>
              <a
                className="btn_get btn_hover new_btn"
                href={data.site.siteMetadata.urls.register}
              >
                Register
              </a>
            </div>
          </div>
        </nav>
      </header>
    </Sticky>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  navClass: PropTypes.string,
  headerClass: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
