/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

import "../assets/themify-icon/themify-icons.css"
import "../assets/simple-line-icon/simple-line-icons.css"
import "../assets/font-awesome/css/all.css"
import "../assets/elagent/style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../assets/animate.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../assets/main.css"
import "../assets/responsive.css"
import Footer from "./footer"

const Layout = ({ children, wrapperClass, navClass, headerClass }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className={`body_wrapper ${wrapperClass || ""}`}>
        <Header
          siteTitle={data.site.siteMetadata.title}
          navClass={navClass}
          headerClass={headerClass}
        />
        <>{children}</>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperClass: PropTypes.string,
  navClass: PropTypes.string,
  headerClass: PropTypes.string,
}

export default Layout
