import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"

const FooterLink = ({ to, name }) => {
  if (/^https?:\/\/.*/i.test(to)) {
    return <a href={to}>{name}</a>
  }
  return <Link to={to}>{name}</Link>
}

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          social {
            twitter
            linkedIn
          }

          footerLinks {
            name
            to
          }

          devLinks {
            name
            to
          }
        }
      }

      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/integrations/" } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 10
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `)

  return (
    <footer className="new_footer_area bg_color">
      <div className="new_footer_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="f_widget about-widget pl_70">
                <h3 className="f-title f_600 t_color f_size_18">Company</h3>
                <ul className="list-unstyled f_list">
                  {data.site.siteMetadata.footerLinks.map(link => (
                    <li key={link.name}>
                      <FooterLink {...link} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="f_widget about-widget pl_70">
                <h3 className="f-title f_600 t_color f_size_18">Developers</h3>
                <ul className="list-unstyled f_list">
                  {data.site.siteMetadata.devLinks.map(link => (
                    <li key={link.name}>
                      <FooterLink {...link} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="f_widget about-widget pl_70">
                <h3 className="f-title f_600 t_color f_size_18">
                  Integrations
                </h3>
                <ul className="list-unstyled f_list">
                  {data.allMarkdownRemark.edges.map(({ node }) => (
                    <li key={node.id}>
                      <FooterLink
                        name={node.frontmatter.title}
                        to={node.frontmatter.path}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="f_widget social-widget pl_70">
                <h3 className="f-title f_600 t_color f_size_18">Social</h3>
                <div className="f_social_icon">
                  <a
                    href={data.site.siteMetadata.social.twitter}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a
                    href={data.site.siteMetadata.social.linkedIn}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bg">
          <div className="footer_bg_one"></div>
          <div className="footer_bg_two"></div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-sm-7">
              <p className="mb-0 f_400">
                HookActions LLC &copy; {new Date().getFullYear()}
              </p>
            </div>
            <div className="col-lg-6 col-sm-5 text-right">
              <p>
                Made with <i className="icon_heart"></i> in Flint, Michigan
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
